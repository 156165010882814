export const toolbar = 'save | undo redo | link | image | ' +
  'insert | styleselect | bold | italic | code | ' +
  'alignleft aligncenter alignright alignjustify | ' +
  'bullist numlist | outdent indent | help'

export const plugins = [
  'advlist autolink lists link image',
  'charmap print preview anchor help',
  'searchreplace visualblocks fullscreen',
  'code',
  'insertdatetime media table paste wordcount save',
]

export const initEditor = {
  height: 500,
  menubar: true,
  branding: false,
  plugins: plugins,
  toolbar: toolbar,
  content_css: [
    '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
    '//www.tiny.cloud/css/codepen.min.css',
  ],
}

export const config = {
  onRemove: '',
  onActivate: '',
  onAddUndo: '',
  onBeforeAddUndo: '',
  onBeforeExecCommand: '',
  onBeforeGetContent: '',
  onBeforePaste: '',
  onBeforeRenderUI: '',
  onBeforeSetContent: '',
  onBlur: '',
  onClearUndos: '',
  onClick: '',
  onContextMenu: '',
  onCopy: '',
  onCut: '',
  onDblclick: '',
  onDeactivate: '',
  onDirty: '',
  onDrag: '',
  onDragDrop: '',
  onDragEnd: '',
  onDragGesture: '',
  onDragOver: '',
  onDrop: '',
  onExecCommand: '',
  onFocus: '',
  onFocusIn: '',
  onFocusOut: '',
  onGetContent: '',
  onHide: '',
  onInit: '',
  onKeyDown: '',
  onKeyPress: '',
  onKeyUp: '',
  onLoadContent: '',
  onMouseDown: '',
  onMouseEnter: '',
  onMouseLeave: '',
  onMouseMove: '',
  onMouseOut: '',
  onMouseOver: '',
  onMouseUp: '',
  onNodeChange: '',
  onObjectResized: '',
  onObjectSelected: '',
  onObjectResizeStart: '',
  onPaste: '',
  onPostProcess: '',
  onPostRender: '',
  onPreProcess: '',
  onProgressState: '',
  onRedo: '',
  onReset: '',
  onSaveContent: '',
  onSelectionChange: '',
  onSetAttrib: '',
  onSetContent: '',
  onShow: '',
  onSubmit: '',
  onUndo: '',
  onVisualAid: '',
}