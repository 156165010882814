import { SocialMediaLinks as Links } from '../../Home/Socials/Links'

const SocialMedia = [
  {
    url: Links.Linkedin,
    value: 'Linkedin',
  },
  {
    url: Links.Twitter,
    value: 'Twitter',
  }
];


export default SocialMedia;